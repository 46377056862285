import { colors } from '@utils/constants/colors';
import * as React from 'react';

function Star(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 18 17' fill='none' {...props}>
      <path
        d='M8.64 2.832l1.47 3.244.276.61.665.073 3.495.388-2.602 2.429-.48.447.13.643.693 3.47-3.144-1.747-.575-.32-.573.323-3.066 1.728.697-3.531.128-.648-.487-.447-2.59-2.374 3.578-.437.652-.08.274-.596L8.64 2.832z'
        fill={props.fill || colors.roveBlue}
        stroke={props.stroke || colors.roveBlue}
        strokeWidth={props.strokeWidth || 1}
      />
    </svg>
  );
}

const MemoStar = React.memo(Star);
export default MemoStar;
