import * as React from "react";

function CreditCard(props) {
  return (
    <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 9H50" stroke="#1D1919" strokeWidth="2"/>
      <path d="M5 19L32 19" stroke="#1D1919" strokeWidth="2"/>
      <rect x="1" y="1" width="48" height="28" rx="4" stroke="#1D1919" strokeWidth="2"/>
    </svg>
  );
}

const MemoCreditCard = React.memo(CreditCard);
export default MemoCreditCard;
