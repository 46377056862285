import * as React from "react";

function Bedroom(props) {
  return (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.90265 12.9405H2.59168C2.24818 12.9405 1.96973 12.6621 1.96973 12.3186V10.3491C1.96973 10.0056 2.24818 9.72711 2.59168 9.72711H14.3051C14.6486 9.72711 14.927 10.0056 14.927 10.3491V12.3186C14.927 12.6621 14.6486 12.9405 14.3051 12.9405H13.9941C13.6506 12.9405 13.3722 12.6621 13.3722 12.3186V11.6966C13.3722 11.3531 13.0937 11.0747 12.7502 11.0747H4.14656C3.80306 11.0747 3.5246 11.3531 3.5246 11.6966V12.3186C3.5246 12.6621 3.24615 12.9405 2.90265 12.9405Z"
        stroke="#F8F4F1" strokeWidth="0.621951"/>
      <path
        d="M1.96943 11.0747H1.0365C0.693008 11.0747 0.414551 10.7962 0.414551 10.4527V8.48321C0.414551 6.74174 2.48772 5.78809 4.24992 5.78809H8.60358"
        stroke="#F8F4F1" strokeWidth="0.621951"/>
      <path
        d="M15.0306 11.0747H15.9635C16.307 11.0747 16.5854 10.7962 16.5854 10.4527V8.48321C16.5854 6.74174 14.5123 5.78809 12.7501 5.78809H8.39642"
        stroke="#F8F4F1" strokeWidth="0.621951"/>
      <path
        d="M3.73145 5.78809V4.75276C3.73145 4.29745 4.27523 4.21811 4.54712 4.23536H7.13009C7.67387 4.23536 7.85513 4.58029 7.87779 4.75276V5.47712"
        stroke="#F8F4F1" strokeWidth="0.621951"/>
      <path
        d="M8.91455 5.47712V4.75276C8.91455 4.29745 9.45833 4.21811 9.73022 4.23536H12.3132C12.857 4.23536 13.0382 4.58029 13.0609 4.75276V5.89175"
        stroke="#F8F4F1" strokeWidth="0.621951"/>
      <path
        d="M1.86572 6.2027V1.53807C1.86572 1.42357 1.95854 1.33075 2.07304 1.33075H3.42939C3.54388 1.33075 3.6367 1.42357 3.6367 1.53807V2.05636C3.6367 2.17086 3.72952 2.26368 3.84402 2.26368H12.9484C13.0629 2.26368 13.1557 2.17086 13.1557 2.05636V1.53807C13.1557 1.42357 13.2485 1.33075 13.363 1.33075H14.7194C14.8339 1.33075 14.9267 1.42357 14.9267 1.53807V6.2027"
        stroke="#F8F4F1" strokeWidth="0.621951"/>
    </svg>
  );
}

const MemoBedroom = React.memo(Bedroom);
export default MemoBedroom;
