import * as React from 'react';

function SepaDebit(props) {
  return (
    <svg
      width="50"
      height="30"
      viewBox="0 0 50 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1"
        y="1"
        width="48"
        height="28"
        rx="4"
        stroke="#1D1919"
        strokeWidth="2"
      />
      <circle cx="24.5" cy="15.5" r="7.75" stroke="black" strokeWidth="1.5" />
      <path
        d="M27.928 15.5C27.928 17.7578 27.4766 19.7648 26.7812 21.1796C26.0619 22.6429 25.2098 23.25 24.5 23.25C23.7902 23.25 22.9381 22.6429 22.2188 21.1796C21.5234 19.7648 21.072 17.7578 21.072 15.5C21.072 13.2422 21.5234 11.2352 22.2188 9.82043C22.9381 8.3571 23.7902 7.75 24.5 7.75C25.2098 7.75 26.0619 8.3571 26.7812 9.82043C27.4766 11.2352 27.928 13.2422 27.928 15.5Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path d="M17.7288 11.3218H31.5593" stroke="black" strokeWidth="1.5" />
      <path d="M17.7288 19.9663H30.9831" stroke="black" strokeWidth="1.5" />
      <path
        d="M16.5763 15.356L32.1356 15.356"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
}

const MemoSepaDebit = React.memo(SepaDebit);
export default MemoSepaDebit;
