import * as React from 'react';

function ChevronRight(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 8 14" fill="none" {...props}>
            <path d="M1 13l6-6-6-6" stroke="#000" />
        </svg>
    );
}

const MemoChevronRight = React.memo(ChevronRight);
export default MemoChevronRight;
