import * as React from "react";

function Bank(props) {
  return (
    <svg width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="1" y="1" width="48" height="28" rx="4" stroke="#1D1919" strokeWidth="2"/>
      <rect x="16" y="21.4766" width="18.2857" height="1.52381" fill="#1D1919"/>
      <rect x="24.3809" y="21.4766" width="9.14286" height="1.52381" transform="rotate(-90 24.3809 21.4766)" fill="#1D1919"/>
      <rect x="30.4766" y="21.4766" width="9.14286" height="1.52381" transform="matrix(0 -1 1 0 9 51.9531)" fill="#1D1919"/>
      <rect x="18.2861" y="21.4766" width="9.14286" height="1.52381" transform="rotate(-90 18.2861 21.4766)" fill="#1D1919"/>
      <rect x="21.333" y="21.4766" width="9.14286" height="1.52381" transform="rotate(-90 21.333 21.4766)" fill="#1D1919"/>
      <rect x="27.4287" y="21.4766" width="9.14286" height="1.52381" transform="rotate(-90 27.4287 21.4766)" fill="#1D1919"/>
      <path d="M33.5236 12.3333H16.7617V10.6923L25.3008 7L33.5236 10.6923V12.3333Z" stroke="#1D1919" strokeWidth="1.52381"/>
      <path d="M25.1426 8.9043V10.4281" stroke="#1D1919" strokeWidth="0.761905"/>
      <path d="M23.6191 10.0469H26.6668" stroke="#1D1919" strokeWidth="0.761905"/>
    </svg>
  );
}

const MemoBank = React.memo(Bank);
export default MemoBank;
