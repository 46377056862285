import { colors } from '@utils/constants/colors';
import * as React from 'react';

function Outdoor(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 28 28' fill='none' {...props}>
      <path
        d='M7.64 23.258l3.519-3.519a.453.453 0 00.132-.32 2.26 2.26 0 00-2.258-2.258h-5.87V9.484a1.355 1.355 0 00-2.71 0v9.935c0 .12.047.235.132.32l3.519 3.52-3.971 3.97a.452.452 0 00.32.771H2.71c.12 0 .235-.047.32-.132l2.842-2.842 2.841 2.841c.085.085.2.133.32.133h2.258a.452.452 0 00.32-.771l-3.972-3.97zM6.51 22.13l2.258-2.258h.98l-2.747 2.748-.49-.49zm-2.258-2.258h3.239L5.87 21.49l-1.619-1.619zm6.058-.903H1.356v-.903h7.677a1.354 1.354 0 011.278.903zM1.807 9.032a.452.452 0 01.452.452v7.677h-.903V9.484c0-.25.202-.452.451-.452zm.716 18.065h-.98l3.2-3.2.49.49-2.71 2.71zm6.697 0L1.994 19.87h.98l7.226 7.226h-.98zM23.897 23.258l3.52-3.52a.452.452 0 00.132-.319V9.484a1.355 1.355 0 00-2.71 0v7.677h-5.87a2.259 2.259 0 00-2.259 2.258c0 .12.048.235.132.32l3.52 3.52-3.971 3.97a.452.452 0 00.32.771h2.257c.12 0 .235-.048.32-.132l2.841-2.842 2.842 2.841c.085.085.2.133.32.133h2.258a.451.451 0 00.32-.771l-3.972-3.97zm1.845-13.774a.452.452 0 01.904 0v7.677h-.904V9.484zm-6.774 8.58h7.678v.904H17.69a1.357 1.357 0 011.278-.904zm3.162 3.426l-1.62-1.62h3.238l-1.618 1.62zm-3.878-1.62h.98l2.258 2.259-.49.49-2.748-2.748zm.529 7.226h-.98l7.225-7.225h.98l-7.225 7.226zm6.697 0l-2.71-2.71.49-.49 3.2 3.2h-.98z'
        fill={props.fill ?? colors.roveBlack}
      />
      <path
        d='M15.355 22.58v-6.135L17.8 14h1.62a1.807 1.807 0 000-3.613h-4.065V5.155L17.8 2.71h8.846a1.355 1.355 0 000-2.71H1.356a1.355 1.355 0 000 2.71H10.2l2.445 2.445v5.232H8.582a1.807 1.807 0 000 3.613H10.2l2.446 2.445v6.135a.452.452 0 00-.452.452v4.516a.452.452 0 00.452.452h2.71a.452.452 0 00.451-.452v-4.516a.452.452 0 00-.451-.452zm0-7.413V14h1.168l-1.168 1.167zm0-11.29V2.71h1.168l-1.168 1.167zM.905 1.355c0-.25.201-.452.45-.452h25.29a.452.452 0 010 .904H1.356a.452.452 0 01-.45-.452zM11.477 2.71h1.168v1.167L11.478 2.71zm2.071 0h.903v7.677h-.903V2.71zm-5.87 9.484a.904.904 0 01.902-.904H19.42a.903.903 0 110 1.807H8.58a.903.903 0 01-.903-.903zM11.477 14h1.168v1.168L11.478 14zm2.071 0h.903v8.58h-.903V14zm1.355 13.097h-1.807v-3.613h1.807v3.613z'
        fill={props.fill ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoOutdoor = React.memo(Outdoor);
export default MemoOutdoor;
