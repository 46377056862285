import { colors } from '@utils/constants/colors';
import * as React from 'react';

function ChevronDown(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 9 6' fill='none' {...props}>
      <path d='M1 1l3.5 3.5L8 1' stroke={props.stroke ?? colors.roveBlack} />
    </svg>
  );
}

const MemoChevronDown = React.memo(ChevronDown);
export default MemoChevronDown;
