import * as React from "react";

function Ergonomic(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 28 37" fill="none" {...props}>
      <path
        d="M27.558 25.519v-8.555c0-.435-.295-.725-.737-.725s-.737.29-.737.725v7.685H14.81V22.69h8.106c1.031 0 1.842-.797 1.842-1.812V10.512C24.758 4.712 19.968 0 14.074 0 8.179 0 3.389 4.712 3.389 10.512v10.294c0 1.015.811 1.813 1.843 1.813h8.105v1.957H1.989V16.53c0-.435-.294-.725-.736-.725s-.737.29-.737.725v8.917C.22 25.81 0 26.316 0 26.823v2.9c0 1.233.958 2.175 2.21 2.175h11.127v2.755h-4.79c-.442 0-.736.29-.736.725s.294.725.736.725H19.6c.442 0 .737-.29.737-.725s-.295-.725-.737-.725h-4.79v-2.755h10.98c1.252 0 2.21-.942 2.21-2.175v-2.9c0-.507-.147-.942-.442-1.304zm-22.695-4.64V10.512c0-5.002 4.126-9.062 9.21-9.062 5.085 0 9.211 4.06 9.211 9.062v10.294c0 .218-.147.363-.368.363H5.232c-.221.072-.369-.145-.369-.29zm21.663 8.844c0 .435-.294.725-.736.725H2.21c-.442 0-.736-.29-.736-.725v-2.9c0-.434.294-.724.737-.724H25.79c.442 0 .736.29.736.724v2.9z"
        fill="#1D1919"
      />
    </svg>
  );
}

const MemoErgonomic = React.memo(Ergonomic);
export default MemoErgonomic;
