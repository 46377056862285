import { colors } from '@utils/constants/colors';
import React from 'react';

function ArrowRight(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 12' fill='none' {...props}>
      <path
        d='M17.889.778L23 5.888 17.889 11M22.489 5.889H0'
        stroke={props.stroke ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoArrowRight = React.memo(ArrowRight);
export default MemoArrowRight;
