import React from 'react';
import { colors } from '@utils/constants/colors';

function Mail(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 27 17' fill='none' {...props}>
      <rect
        x={0.54}
        y={0.54}
        width={25.92}
        height={15.12}
        rx={2.16}
        stroke={props.stroke ?? colors.roveBlack}
      />
      <path
        d='M1.08 1.08l10.585 7.527a2.7 2.7 0 003.13 0L25.38 1.08M7.56 5.94l-6.48 9.18M25.38 15.12L18.9 5.94'
        stroke={props.stroke ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoMail = React.memo(Mail);
export default MemoMail;
