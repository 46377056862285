import * as React from 'react';

function LandingCheckmark(props) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 9.60498L8.06081 15.5984L20 0.770691"
        stroke="white"
        strokeWidth="2.11824"
      />
    </svg>
  );
}

const MemoLandingCheckmark = React.memo(LandingCheckmark);
export default MemoLandingCheckmark;
