import * as React from 'react';
import { colors } from '@utils/constants/colors';

function Envelope(props) {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.80641 8.47151L0.159779 1.04634C0.303774 0.735181 0.53711 0.471097 0.831738 0.285596C1.12654 0.100098 1.47012 0.00119312 1.82147 0.000732422H20.1781C20.5294 0.00119387 20.873 0.100094 21.1678 0.285596C21.4625 0.471093 21.6958 0.735193 21.8398 1.04634L14.1931 8.47151C13.3457 9.29302 12.197 9.75431 10.9996 9.75431C9.80222 9.75431 8.65356 9.29302 7.80609 8.47151H7.80641ZM6.51855e-07 1.76142V14.2429C-0.000316146 14.6056 0.114846 14.9595 0.329808 15.2562L7.11388 8.66895L6.51855e-07 1.76142ZM10.9999 10.3687C9.7336 10.3707 8.51134 9.91827 7.56768 9.09815L0.77833 15.6905H0.778489C1.0839 15.8998 1.44854 16.0121 1.8224 16.0121H20.1782C20.5521 16.0121 20.9167 15.8998 21.2221 15.6905L14.4328 9.09815H14.4329C13.4891 9.91843 12.2664 10.3709 10.9997 10.3687H10.9999ZM21.9999 1.76142L14.8861 8.66879L21.6702 15.256C21.8852 14.9593 22.0003 14.6054 22 14.2427L21.9999 1.76142Z"
        fill={props.fill ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoEnvelope = React.memo(Envelope);
export default MemoEnvelope;
