import * as React from 'react';

function ArrowRightTan(props) {
  return (
    <svg
      width="16"
      height="6"
      viewBox="0 0 16 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector 57"
        d="M16 3L11 0.113249V5.88675L16 3ZM0 3.5H11.5V2.5H0V3.5Z"
        fill={props.fill || '#C3BEB4'}
      />
    </svg>
  );
}

const MemoArrowRightTan = React.memo(ArrowRightTan);
export default MemoArrowRightTan;
