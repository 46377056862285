import * as React from 'react';
import { colors } from '@utils/constants/colors';

function Cellphone(props) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9099 12.1636C16.0745 12.8112 17.2398 13.459 18.4043 14.1067C18.9056 14.3852 19.1255 14.9762 18.9281 15.5152C17.9261 18.2545 14.9916 19.7004 12.2304 18.6919C6.57638 16.6267 2.38539 12.4325 0.321453 6.77445C-0.686359 4.01132 0.758568 1.07463 3.49587 0.0719697C4.03451 -0.125519 4.62509 0.0945171 4.90409 0.596148C5.55062 1.76158 6.19793 2.92769 6.8451 4.09305C7.14848 4.63977 7.07717 5.28873 6.66194 5.7556C6.11815 6.36796 5.57446 6.98018 5.03067 7.59189C6.19143 10.4205 8.58683 12.8177 11.4135 13.9793C12.0248 13.4351 12.6366 12.891 13.2485 12.3468C13.7157 11.9313 14.3637 11.8599 14.9099 12.1635L14.9099 12.1636Z"
        fill={props.fill ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoCellphone = React.memo(Cellphone);
export default MemoCellphone;
