import React from 'react';

function ExpandAll(props) {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.30957 2.91992L6.66806 5.52698C6.87761 5.62893 7.12239 5.62893 7.33193 5.52697L12.6899 2.91992"
            stroke={props.stroke ?? "#9F998E"}
            strokeWidth="1.2139" strokeLinecap="round"/>
      <path d="M1.30957 5.91992L6.66806 8.52698C6.87761 8.62893 7.12239 8.62893 7.33193 8.52697L12.6899 5.91992"
            stroke={props.stroke ?? "#9F998E"} strokeWidth="1.2139" strokeLinecap="round"/>
      <path d="M1.30957 8.91992L6.66806 11.527C6.87761 11.6289 7.12239 11.6289 7.33193 11.527L12.6899 8.91992"
            stroke={props.stroke ?? "#9F998E"} strokeWidth="1.2139" strokeLinecap="round"/>
    </svg>
)
  ;
}

const MemoExpandAll = React.memo(ExpandAll);
export default MemoExpandAll;
