import React from 'react';

function Logo(props) {
  return (
    <svg
      width={85}
      height={19}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='m10.04 18.814-4.669-7.77H1.89v7.77H0V.213h5.668c2.105 0 3.698.497 4.778 1.49 1.097.994 1.646 2.297 1.646 3.912 0 1.348-.396 2.501-1.188 3.46-.773.94-1.925 1.552-3.454 1.835l4.858 7.904h-2.267ZM1.89 9.527h3.805c1.476 0 2.582-.355 3.32-1.065.756-.71 1.134-1.659 1.134-2.847 0-1.224-.36-2.164-1.08-2.82-.72-.675-1.853-1.012-3.4-1.012h-3.78v7.744ZM31.064 19c-1.745 0-3.328-.4-4.75-1.198a9.01 9.01 0 0 1-3.374-3.379c-.81-1.455-1.214-3.096-1.214-4.923s.404-3.46 1.214-4.896c.828-1.455 1.952-2.582 3.374-3.38C27.736.408 29.32 0 31.064 0c1.764 0 3.356.408 4.778 1.224 1.422.798 2.537 1.925 3.347 3.38.81 1.437 1.214 3.069 1.214 4.896S40 12.968 39.19 14.423a8.814 8.814 0 0 1-3.347 3.38C34.42 18.6 32.828 19 31.065 19Zm0-1.623c1.404 0 2.664-.32 3.78-.958 1.115-.639 1.997-1.553 2.644-2.741.648-1.207.972-2.6.972-4.178 0-1.579-.324-2.963-.972-4.151-.647-1.189-1.53-2.103-2.645-2.741-1.115-.639-2.375-.958-3.779-.958-1.403 0-2.663.32-3.778.958-1.116.639-1.998 1.552-2.645 2.74-.648 1.19-.972 2.573-.972 4.152 0 1.579.324 2.972.972 4.178.647 1.188 1.53 2.102 2.645 2.74 1.115.64 2.375.959 3.778.959ZM65.561.213l-7.152 18.6h-2.16L49.098.214h2.051L57.33 16.79 63.564.213h1.997ZM77.038 1.73v6.945h7.152v1.543h-7.152v7.052H85v1.544h-9.852V.186H85V1.73h-7.962Z'
        fill={props.fill}
      />
    </svg>
  );
}

const MemoLogo = React.memo(Logo);
export default MemoLogo;
