import * as React from "react";

function Bathroom(props) {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.72395 0.384705C10.1968 0.794318 9.78248 1.40874 9.25612 2.02316C8.5543 2.84239 8.67133 3.59334 8.90525 3.76401"
        stroke="#F8F4F1" strokeWidth="0.724138" strokeLinecap="round"/>
      <path
        d="M11.4134 0.384705C11.8862 0.794318 11.4719 1.40874 10.9456 2.02316C10.2438 2.84239 10.3608 3.59334 10.5947 3.76401"
        stroke="#F8F4F1" strokeWidth="0.724138" strokeLinecap="round"/>
      <path
        d="M13.1033 0.384705C13.5762 0.794318 13.1619 1.40874 12.6355 2.02316C11.9337 2.84239 12.0507 3.59334 12.2846 3.76401"
        stroke="#F8F4F1" strokeWidth="0.724138" strokeLinecap="round"/>
      <rect x="0.637931" y="5.33297" width="14.7241" height="1.68966" rx="0.844828" stroke="#F8F4F1"
            strokeWidth="0.724138"/>
      <path
        d="M2.08619 7.02262H13.9138V8.83297C13.9138 10.2327 12.779 11.3674 11.3793 11.3674H4.62067C3.22092 11.3674 2.08619 10.2327 2.08619 8.83297V7.02262Z"
        stroke="#F8F4F1" strokeWidth="0.724138"/>
      <path
        d="M2.81024 10.8847C2.23093 12.4295 2.32745 12.695 2.81024 12.9364C3.41366 13.1778 4.05737 12.1318 4.25852 11.6088"
        stroke="#F8F4F1" strokeWidth="0.724138"/>
      <path
        d="M13.0692 10.8847C13.6485 12.4295 13.5519 12.695 13.0692 12.9364C12.4657 13.1778 11.822 12.1318 11.6209 11.6088"
        stroke="#F8F4F1" strokeWidth="0.724138"/>
      <path d="M2.93102 5.21229V1.83298H1.72412" stroke="#F8F4F1" strokeWidth="0.724138" strokeLinecap="round"/>
      <path d="M1.72412 5.21229V1.83298" stroke="#F8F4F1" strokeWidth="0.724138" strokeLinecap="round"/>
      <path d="M1.96533 2.91919H3.65499C4.71706 2.91919 4.90211 3.56287 4.86188 3.88471" stroke="#F8F4F1"
            strokeWidth="0.724138" strokeLinecap="round"/>
    </svg>
  );
}

const MemoBathroom = React.memo(Bathroom);
export default MemoBathroom;
