import React from 'react';

function Google(props) {
  return (
    <svg viewBox='0 0 24 24' width='1em' height='1em' {...props}>
      <path d='M12.545 12.151a1.91 1.91 0 001.909 1.909h3.536c-.607 1.972-2.101 3.467-4.26 3.866-3.431.635-6.862-1.865-7.19-5.339a6.034 6.034 0 018.782-5.941 1.958 1.958 0 002.286-.368 1.992 1.992 0 00-.498-3.179 10.005 10.005 0 00-5.692-1.038c-4.583.502-8.31 4.226-8.812 8.809A10.002 10.002 0 0012.545 22c6.368 0 8.972-4.515 9.499-8.398.242-1.78-1.182-3.352-2.978-3.354l-4.61-.006a1.908 1.908 0 00-1.911 1.909z' />
    </svg>
  );
}

const MemoGoogle = React.memo(Google);
export default MemoGoogle;
