import * as React from 'react';

function Play(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 25 29' fill='none' {...props}>
      <path d='M25 14.5L.25 28.79V.21L25 14.5z' fill='#000' />
    </svg>
  );
}

const MemoPlay = React.memo(Play);
export default MemoPlay;
