import * as React from 'react';

function CircledChevronLeft(props) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="25"
        cy="25"
        r="24.575"
        transform="rotate(180 25 25)"
        stroke={props.stroke ?? "#fff"}
        strokeWidth="0.85"
      />
      <path
        d="M28.334 37.5L15.834 25L28.334 12.5"
        stroke={props.stroke ?? "#fff"}
        strokeWidth="0.85"
      />
    </svg>
  );
}

const MemoCircledChevronLeft = React.memo(CircledChevronLeft);
export default MemoCircledChevronLeft;
