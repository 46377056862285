import React from 'react';

function ArrowButton({ bgFill, arrowFill, ...rest }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="18" height="18" rx="9" fill={bgFill} />
      <path
        d="M12.6402 9.26517C12.7866 9.11872 12.7866 8.88128 12.6402 8.73483L10.2537 6.34835C10.1072 6.2019 9.8698 6.2019 9.72335 6.34835C9.5769 6.4948 9.5769 6.73223 9.72335 6.87868L11.8447 9L9.72335 11.1213C9.5769 11.2678 9.5769 11.5052 9.72335 11.6517C9.8698 11.7981 10.1072 11.7981 10.2537 11.6517L12.6402 9.26517ZM5.625 9.375H12.375V8.625H5.625V9.375Z"
        fill={arrowFill}
      />
    </svg>
  );
}

const MemoArrowButton = React.memo(ArrowButton);
export default MemoArrowButton;
