import React from 'react';
import { colors } from '@utils/constants/colors';

function Cross(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M1 1l14.5 14.5M1 15.5L15.5 1"
        stroke={props.stroke ?? colors.roveDarkBlack}
      />
    </svg>
  );
}

const MemoCross = React.memo(Cross);
export default MemoCross;
