import { colors } from '@utils/constants/colors';
import * as React from 'react';

function Office(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 28 28' fill='none' {...props}>
      <path
        d='M26.512 11.464h-5.898v-1.267a.386.386 0 00-.386-.386h-2.48a.386.386 0 00-.386.386v1.267h-1.708V9.04h3.472a1.488 1.488 0 001.488-1.488V1.488A1.488 1.488 0 0019.126 0H8.874a1.488 1.488 0 00-1.488 1.488v6.063a1.488 1.488 0 001.488 1.488h3.472v2.425h-1.212v-1.267a.386.386 0 00-.386-.386H1.929a.386.386 0 00-.386.386v1.267h-.055a1.488 1.488 0 100 2.977h.607v13.173c0 .213.172.386.385.386h1.874a.386.386 0 00.386-.386v-2.15h11.024v2.15c0 .213.173.386.386.386h1.874a.386.386 0 00.385-.386v-2.15h4.85v2.15c0 .213.174.386.387.386h1.874a.386.386 0 00.386-.386V14.441h.606a1.488 1.488 0 000-2.977zm-8.378-.882h1.709v.882h-1.71v-.882zm-9.977-3.03V1.487c0-.395.321-.716.717-.716h10.252c.396 0 .717.32.717.716v6.063c0 .396-.321.717-.717.717H8.874a.717.717 0 01-.717-.717zm4.961 1.487h1.764v2.425h-1.764V9.04zM2.315 10.582h8.047v.882H2.315v-.882zm1.653 16.646H2.866V14.441h1.102v12.787zm11.795-12.787v8.378H4.74V14.44h11.023zM4.74 24.693V23.59h11.023v1.103H4.74zm12.897 2.535h-1.102V14.441h1.102v12.787zm5.622-12.787v1.984h-4.85v-1.984h4.85zm0 4.74h-4.85v-1.984h4.85v1.984zm-4.85.771h4.85v1.985h-4.85v-1.985zm0 4.74v-1.984h4.85v1.985h-4.85zm6.725 2.536H24.03V14.441h1.103v12.787zm1.378-13.559H1.488a.717.717 0 010-1.433h25.024a.717.717 0 010 1.433z'
        fill={props.fill ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoOffice = React.memo(Office);
export default MemoOffice;
