import React from 'react';

function Facebook(props) {
  return (
    <svg viewBox='0 0 32 32' width='1em' height='1em' {...props}>
      <path d='M19.254 2C15.312 2 13 4.082 13 8.826V13H8v5h5v12h5V18h4l1-5h-5V9.672C18 7.885 18.583 7 20.26 7H23V2.205C22.526 2.141 21.145 2 19.254 2z' />
    </svg>
  );
}

const MemoFacebook = React.memo(Facebook);
export default MemoFacebook;
