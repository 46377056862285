import { colors } from '@utils/constants/colors';
import * as React from 'react';

function Spaces(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 28 18' fill='none' {...props}>
      <path
        d='M25.55 4.241V2.714A2.715 2.715 0 0022.836 0H5.08C3.61 0 2.422 1.187 2.422 2.714v1.527C1.178 4.524.217 5.485.047 6.729c-.282 1.527.735 2.884 2.15 3.223v5.259c0 .17.225.396.452.396h1.187v1.583c0 .226.17.452.452.452H5.42c.17 0 .283-.056.396-.226l1.018-1.753h14.363l1.018 1.753a.476.476 0 00.396.226h1.13c.227 0 .453-.17.453-.452v-1.583h1.074c.226 0 .453-.17.453-.453V9.952c1.413-.34 2.431-1.64 2.262-3.223-.227-1.244-1.188-2.205-2.432-2.488zM3.214 2.714c0-.961.848-1.81 1.866-1.81h17.813c1.017 0 1.866.849 1.866 1.867v1.413c-.792.057-1.584.792-1.584 1.697v5.09l-1.3-.001V7.803c0-.565-.453-1.017-1.018-1.017h-5.09c-.565 0-1.017.452-1.017 1.017v3.167h-1.753V7.803c0-.565-.453-1.017-1.018-1.017h-5.09c-.565 0-.96.452-.96 1.017v3.167H4.74V5.937c0-.905-.735-1.64-1.527-1.696V2.714zm1.527 9.104h18.378v1.188H4.74v-1.188zm7.52-.848H6.89V7.803c0-.056.056-.17.17-.17h5.089c.056 0 .17.057.17.17v3.167h-.057zm8.765 0h-5.371V7.803c0-.056.056-.17.17-.17h5.088c.057 0 .17.057.17.17v3.167h-.056zm-15.89 5.768h-.452v-1.131h1.131l-.678 1.13zm18.096 0h-.453l-.678-1.131h1.13v1.13zM25.21 9.16c-.226 0-.396.227-.396.396v5.203H3.044V9.556a.386.386 0 00-.395-.395C1.518 9.048.613 8.086.783 6.842c.282-1.922 3.11-2.488 3.11-.905v7.465c0 .226.17.452.452.452h19.226c.226 0 .452-.17.452-.452V5.88c0-1.527 2.828-.961 3.11.961.114 1.188-.791 2.205-1.922 2.318z'
        fill={props.fill ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoSpaces = React.memo(Spaces);
export default MemoSpaces;
