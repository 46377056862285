import { colors } from '@utils/constants/colors';
import * as React from 'react';

function Home(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 25 28' fill='none' {...props}>
      <path
        d='M0 26.655a.291.291 0 00.29.29h.326v.764a.29.29 0 00.29.291H2.54a.29.29 0 00.256-.151l.496-.904h17.455l.496.904c.052.093.15.151.256.151h1.636a.29.29 0 00.29-.29v-.765h.509a.29.29 0 00.29-.29v-5.302a.29.29 0 00-.29-.29H7.663V.29A.29.29 0 007.374 0H.29A.29.29 0 000 .29v26.365zm.581-12.89H7.08v12.6H.58v-12.6zm1.79 13.654H1.209v-.473h1.423l-.261.473zm20.472 0h-1.162l-.261-.47h1.432l-.009.47zm.799-1.052H15.94v-4.723h7.699l.003 4.723zm-8.283-4.723v4.72H7.66v-4.72h7.699zM7.079.58v12.603H.581V.581h6.498z'
        fill={props.fill ?? colors.roveBlack}
      />
      <path
        d='M10.13 2.179a.29.29 0 00-.29.29v16.38a.29.29 0 00.29.29h11.731a.29.29 0 00.29-.29V2.469a.29.29 0 00-.29-.29h-11.73zm11.44 16.379H10.42V2.76h11.15v15.798z'
        fill={props.fill ?? colors.roveBlack}
      />
      <path
        d='M19.15 6.737l-5.848 5.842a.292.292 0 00-.016.426.29.29 0 00.426-.016L19.56 7.14a.29.29 0 00-.41-.404zM17.239 11.344l-1.243 1.235a.29.29 0 00-.017.426.29.29 0 00.426-.016l1.235-1.235a.29.29 0 00-.41-.41h.009zM14.743 9.5a.29.29 0 00.207-.084l2.93-2.934a.29.29 0 00-.409-.41l-2.928 2.931a.29.29 0 00.206.497h-.006z'
        fill={props.fill ?? colors.roveBlack}
      />
      <path
        d='M11.074 18.122h9.843a.29.29 0 00.29-.29V3.486a.29.29 0 00-.29-.29h-9.843a.29.29 0 00-.29.29v14.346a.29.29 0 00.29.29zm.29-14.343h9.262v13.762h-9.262V3.78zM16.672 25.966h6.238a.29.29 0 00.29-.29v-3.341a.29.29 0 00-.29-.29h-6.238a.29.29 0 00-.29.29v3.34a.29.29 0 00.29.291zm.291-3.34h5.656v2.76h-5.657l.001-2.76zM8.393 25.966h6.237a.29.29 0 00.29-.29v-3.341a.29.29 0 00-.29-.29H8.393a.29.29 0 00-.29.29v3.34a.29.29 0 00.29.291zm.29-3.34h5.656v2.76H8.683v-2.76z'
        fill={props.fill ?? colors.roveBlack}
      />
      <path
        d='M12.48 23.714h-1.932a.29.29 0 100 .581h1.932a.29.29 0 100-.58zM21.007 23.714h-1.932a.29.29 0 100 .581h1.932a.29.29 0 100-.58zM1.316 25.966h5.028a.29.29 0 00.291-.29V14.453a.29.29 0 00-.29-.29h-5.03a.29.29 0 00-.29.29v11.223a.29.29 0 00.29.29zm.29-11.22h4.448v10.64H1.606v-10.64zM1.316 12.838h5.028a.29.29 0 00.291-.29V1.324a.29.29 0 00-.29-.29h-5.03a.29.29 0 00-.29.29v11.222a.29.29 0 00.29.29zm.29-11.22h4.448v10.639H1.606V1.618z'
        fill={props.fill ?? colors.roveBlack}
      />
      <path
        d='M5.217 18.558a.29.29 0 00-.29.29v2.507a.29.29 0 10.581 0v-2.507a.29.29 0 00-.29-.29zM5.217 4.968a.29.29 0 00-.29.29v2.508a.29.29 0 00.581 0V5.258a.29.29 0 00-.29-.29z'
        fill={props.fill ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoHome = React.memo(Home);
export default MemoHome;
