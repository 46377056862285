import { colors } from '@utils/constants/colors';
import React from 'react';

function ArrowLeft(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 12' fill='none' {...props}>
      <path
        d='M5.667 10.333L1 5.667 5.667 1M1.467 5.667H22'
        stroke={props.stroke ?? colors.roveBlack}
      />
    </svg>
  );
}

const MemoArrowLeft = React.memo(ArrowLeft);
export default MemoArrowLeft;
