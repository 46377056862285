import * as React from 'react';

function CircledChevronRight(props) {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="25.666"
        cy="25"
        r="24.575"
        stroke={props.stroke ?? "#fff"}
        strokeWidth="0.85"
      />
      <path
        d="M22.332 12.5L34.832 25L22.332 37.5"
        stroke={props.stroke ?? "#fff"}
        strokeWidth="0.85"
      />
    </svg>
  );
}

const MemoCircledChevronRight = React.memo(CircledChevronRight);
export default MemoCircledChevronRight;
