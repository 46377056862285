import * as React from "react";

function ChevronLeft(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 12" fill="none" {...props}>
      <path d="M6.111 11l-5.11-5.111L6.11.778" stroke="#1D1919" />
    </svg>
  );
}

const MemoChevronLeft = React.memo(ChevronLeft);
export default MemoChevronLeft;
